import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { usePostHog } from "posthog-js/react";

import showToast from "@/utils/toast/helpers";
import { getUnreadNotificationsCount } from "@/api/user/notificationApi";

import "react-date-picker/dist/DatePicker.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-calendar/dist/Calendar.css";
import "@/css/app.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modern-drawer/dist/index.css";

import { setUserConnect, setPermissions } from "@/store/userSlice";
import {
  setActiveApp,
  selectActiveApp,
  setApps,
  selectApps,
  selectActiveCompany,
} from "@/store/appSlice";

import { setUnreadNotificationCount } from "@/store/notificationSlice";
import { AuthPageLayout, DashboardPageLayout } from "@/layout";

import { Login, ForgotPassword, ResetPassword } from "@/components/auth";
import {
  IconSprite,
  Unauthorized,
  RequirePermission,
} from "@/components/common";
import { formatNavbarItems } from "@/components/dashboard/data";

import { useUser } from "@/hooks";
import { getUser } from "@/api/user/profileApi";
import { getToken, resetLocalStorage, setUserLoginId } from "@/api/user/authApi";
import { getAllPermissions } from "@/api/user/permissionApi";
import getNavbarItems from "@/api/user/navbarApi";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

import { VENDOR_USER_TYPE, SUPPLIER_USER_TYPE } from "@/utils/userTypes";
import { getFullName } from "@/utils/helpers";

// User Module Imports Start //
import Notifications from "@/pages/user/list-views/Notifications";

import ProfileSettings from "@/pages/user/forms/ProfileSettings";
// User Module Imports End //

// Dynamic Module Imports Start //
import Dashboard from "@/pages/dynamic/dashboard-views/Dashboard";
import DynamicDashboard from "@/pages/dynamic/dashboard-views/DynamicDashboard";

import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";

import DynamicObjectForm from "@/pages/dynamic/forms/DynamicObjectForm";

import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
// Dynamic Module Imports End //

// Admin Module Imports Start //
import DynamicObjectManager from "@/pages/admin/list-views/DynamicObjectManager";
import CompanySettingsListView from "@/pages/admin/list-views/CompanySettingsListView";
import UserManagement from "@/pages/admin/list-views/UserManagement";
import DynamicDashboardManager from "@/pages/admin/list-views/DynamicDashboardManager";

import CompanySettings from "@/pages/admin/forms/CompanySettings";

import DynamicObjectFieldsManager from "@/pages/admin/detail-views/DynamicObjectFieldsManager";
// Admin Module Imports End //

// Facility Management Module Imports Start //
import PlanBoard from "@/pages/facility/dashboard-views/PlanBoard";
import FacilityDashboard from "@/pages/facility/dashboard-views/FacilityDashboard";
import PettyCashDashboard from "@/pages/facility/dashboard-views/PettyCashDashboard";

import BlanketAgreementOverview from "@/pages/facility/list-views/BlanketAgreementOverview";
import WorkOrderOverview from "@/pages/facility/list-views/WorkOrderOverview";
import PettyCashTransactionOverview from "@/pages/facility/list-views/PettyCashTransactionOverview";

import WorkOrderForm from "@/pages/facility/forms/WorkOrderForm";
import ProformaInvoiceForm from "@/pages/facility/forms/ProformaInvoiceForm";
import ProposeJobTimeForm from "@/pages/facility/forms/ProposeJobTimeForm";
import RequestForQuotationForm from "@/pages/facility/forms/RequestForQuotationForm";
import PettyCashClaimForm from "@/pages/facility/forms/PettyCashClaimForm";
import SupervisorPettyCashRequestForm from "@/pages/facility/forms/SupervisorPettyCashRequestForm";
import VisualInspectionForm from "@/pages/facility/forms/VisualInspectionForm";
import IncidentReportForm from "@/pages/facility/forms/IncidentReportForm";
import BlanketAgreementForm from "@/pages/facility/forms/BlanketAgreementForm";
import SupplierForm from "@/pages/facility/forms/SupplierForm";
import EmployeeForm from "@/pages/facility/forms/EmployeeForm";
import ServiceRequestForm from "@/pages/facility/forms/ServiceRequestForm";

import WorkOrderDetails from "@/pages/facility/detail-views/WorkOrderDetails";
// Facility Management Module Imports End //

// Finance Module Imports Start //
import AssetRegisterOverview from "@/pages/finance/list-views/AssetRegisterOverview";
import JournalEntryList from "@/pages/finance/list-views/JournalEntryList";
import BankReconciliation from "@/pages/finance/list-views/BankReconciliation";
import TaxRule from "@/pages/finance/list-views/TaxRule";
import ChartOfAccounts from "@/pages/finance/list-views/ChartOfAccounts";
import Currency from "@/pages/finance/list-views/Currency";
import AccountingPeriod from "@/pages/finance/list-views/AccountingPeriod";
import Budget from "@/pages/finance/list-views/Budget";
import BudgetCategory from "@/pages/finance/list-views/BudgetCategory";

import JournalEntry from "@/pages/finance/forms/JournalEntry";
import AddTaxRule from "@/pages/finance/forms/AddTaxRule";
import PurchaseOrderForm from "@/pages/finance/forms/PurchaseOrderForm";
import PurchaseInvoiceForm from "@/pages/finance/forms/PurchaseInvoiceForm";
import AddGLAccount from "@/pages/finance/forms/AddGLAccount";
import AddCurrency from "@/pages/finance/forms/AddCurrency";
import AddAccountingPeriod from "@/pages/finance/forms/AddAccountingPeriod";
import BudgetForm from "@/pages/finance/forms/BudgetForm";
import BudgetCategoryForm from "@/pages/finance/forms/BudgetCategoryForm";
import SubmitQuotationForm from "@/pages/finance/forms/SubmitQuotationForm";
import PaymentReceiptForm from "@/pages/finance/forms/PaymentReceiptForm";
import RentalInvoiceForm from "@/pages/finance/forms/RentalInvoiceForm";
import CreditNoteForm from "@/pages/finance/forms/CreditNoteForm";
import ReservationForm from "@/pages/finance/forms/ReservationForm";
import GeneralInvoiceForm from "@/pages/finance/forms/GeneralInvoiceForm";
import ExpenseForm from "@/pages/finance/forms/ExpenseForm";
import GeneralReceiptForm from "@/pages/finance/forms/GeneralReceiptForm";
import PrintReceiptForm from "@/pages/finance/forms/PrintReceiptForm";
import BulkReceiptForm from "@/pages/finance/forms/BulkReceiptForm";
import BulkInvoiceForm from "@/pages/finance/forms/BulkInvoiceForm";
import ClearChequeForm from "@/pages/finance/forms/ClearChequeForm";
import WarehouseChequeForm from "@/pages/finance/forms/WarehouseChequeForm";
import BounceChequeForm from "@/pages/finance/forms/BounceChequeForm";
import RecallChequeForm from "@/pages/finance/forms/RecallChequeForm";
import GRNForm from "@/pages/finance/forms/GRNForm";
import PaymentForm from "@/pages/finance/forms/PaymentForm";
import SupplierCreditNoteForm from "@/pages/finance/forms/SupplierCreditNoteForm";
import AMCInvoiceForm from "@/pages/finance/forms/AMCInvoiceForm";
import DepreciationForm from "@/pages/finance/forms/DepreciationForm";
import RevaluationForm from "@/pages/finance/forms/RevaluationForm";
import ExpenseRecognitionForm from "@/pages/finance/forms/ExpenseRecognitionForm";

import CloseAccountingPeriod from "@/pages/finance/detail-views/CloseAccountingPeriod";
import StaticObjectDetails from "@/pages/finance/detail-views/StaticObjectDetails";

import ReportList from "@/pages/finance/report/ReportList";
import Report from "@/pages/finance/report/Report";
// Finance Module Imports End //

// Leasing Module Imports Start //
import ContractOverview from "@/pages/leasing/list-views/ContractOverview";
import TechnicianOverview from "@/pages/leasing/list-views/TechnicianOverview";

import BuildingForm from "@/pages/leasing/forms/BuildingForm";
import TenantForm from "@/pages/leasing/forms/TenantForm";
import UnitForm from "@/pages/leasing/forms/UnitForm";
import RevenueRecognitionForm from "@/pages/leasing/forms/RevenueRecognitionForm";
import FacilityContractForm from "@/pages/leasing/forms/FacilityContractForm";
import MoveInRequestForm from "@/pages/leasing/forms/MoveInRequestForm";
import MoveInCheckListForm from "@/pages/leasing/forms/MoveInCheckListForm";
import ContractForm from "@/pages/leasing/forms/ContractForm";
import ContractTerminationForm from "@/pages/leasing/forms/ContractTerminationForm";
import CustomerQuotationForm from "@/pages/leasing/forms/CustomerQuotationForm";

import BuildingDetails from "@/pages/leasing/detail-views/BuildingDetails";
import UnitDetails from "@/pages/leasing/detail-views/UnitDetails";
import TenantDetails from "@/pages/leasing/detail-views/TenantDetails";
import TechnicianDetails from "@/pages/leasing/detail-views/TechnicianDetails";
// Leasing Module Imports End //

// Sales Module Imports Start //
import LeadOverview from "@/pages/sales/list-views/LeadOverview";

import LeadForm from "@/pages/sales/forms/LeadForm";
import CustomerForm from "@/pages/sales/forms/CustomerForm";

import UnitMatching from "@/pages/sales/detail-views/UnitMatching";
// Sales Module Imports End //

// Warehousing Module Imports Start //
import ItemForm from "@/pages/warehouse/forms/ItemForm";
// Warehousing Module Imports End //

// Warehousing Module Imports Start //
import TasksDashboard from "@/pages/workflow/dashboard-views/TasksDashboard";

import ViewTask from "@/pages/workflow/detail-views/ViewTask";
import TaskFlowChart from "@/pages/workflow/detail-views/TaskFlowChart";
// Warehousing Module Imports End //

import {
  ReportSupplierStatementWrapper, ReportTenantStatement, ReportSupplierPaidInvoicesWrapper,
  ReportBankAccountStatementWrapper, ReportPettyCashBySupervisorStatementWrapper,
  ReportPettyCashClaimStatementWrapper
} from "@/components/finance/reporting";
import { RequestForQuotationOverview } from "./pages/facility";

const getUserData = async (userType, userId) => {
  let objectName;

  switch (userType) {
    case VENDOR_USER_TYPE:
    case SUPPLIER_USER_TYPE:
      objectName = dynamicObjectMap.get("SupplierStaffObjectName");
      break;

    default:
      objectName = dynamicObjectMap.get("EmployeeObjectName");
      break;
  }

  return getDynamicObjectRecords(objectName, {
    userId,
  });
};

function App() {
  const apps = useSelector(selectApps);
  const dispatch = useDispatch();
  const activeApp = useSelector(selectActiveApp);
  const location = useLocation();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const { user, userType } = useUser();
  const activeCompany = useSelector(selectActiveCompany);

  const isLoggedIn = Boolean(user?.userId) && Boolean(activeCompany?.id);

  const { data: permissionsRes, isLoading: isLoadingPermissions } = useQuery(
    ["permissions", user?.userId, activeCompany?.id],
    getAllPermissions,
    {
      enabled: isLoggedIn,
    }
  );

  const { data: userData, isLoading: isLoadingUser } = useQuery(
    ["user-data", user?.userId, activeCompany?.id],
    () => getUserData(userType, user.userId),
    {
      enabled: isLoggedIn,
    }
  );

  const { data: navbarItems, isLoading: isLoadingNavbarItems } = useQuery(
    ["navbar-items", user?.userId, activeCompany?.id],
    () => getNavbarItems(activeCompany),
    {
      enabled: isLoggedIn,
    }
  );

  const { data: notificationsCount } = useQuery(
    ["unread-notifications-count"],
    () => getUnreadNotificationsCount(),
    {
      enabled: isLoggedIn,
    }
  );

  useEffect(() => {
    if (!notificationsCount) return;
    dispatch(setUnreadNotificationCount(notificationsCount.unreadCounts));
  }, [notificationsCount]);

  useEffect(() => {
    if (!isLoadingUser && userData?.data && userData?.data?.length) {
      const userDetail = userData.data[0];
      const userEmail = userDetail?.email || userDetail?.personalEmail;

      const userPayload = {
        ...user,
        userId: userDetail?.userId,
        userKey: userDetail?.id,
        companies: user?.companies,
        name: getFullName(userDetail),
        supplier: userDetail.supplier,
        email: userEmail,
      };

      if (
        Array.isArray(userDetail?.profilePicture) &&
        userDetail?.profilePicture.length
      ) {
        userPayload.profilePicture = userDetail?.profilePicture[0];
      }

      dispatch(setUserConnect(userPayload));
    }
  }, [userData]);

  const setAppsData = () => {
    const sidebarItems = formatNavbarItems(
      navbarItems?.data,
      permissionsRes?.data,
      user.roles
    );
    dispatch(setApps(sidebarItems));
  };

  useEffect(() => {
    if (!isLoadingPermissions && permissionsRes) {
      dispatch(setPermissions(permissionsRes.data));
    }
  }, [isLoadingPermissions, permissionsRes]);

  useEffect(() => {
    if (
      !isLoadingNavbarItems &&
      navbarItems &&
      !isLoadingPermissions &&
      permissionsRes
    ) {
      setAppsData();
    }
  }, [isLoadingNavbarItems, userType, navbarItems]);

  const onLoggedOut = () => {
    const token = getToken();

    if (token) {
      showToast("Your session has expired", "error");
    }
    const prevRoute = location.pathname;
    if ((prevRoute && prevRoute.includes("login")) || prevRoute === "/") {
      navigate("/login");
    } else {
      navigate(`/login?redirect=${prevRoute}`);
    }
    resetLocalStorage();
  };

  const verifyToken = useMutation(getUser, {
    onSuccess: (data) => {
      posthog?.identify(data.id, {
        email: data.email,
        username: data.username
      });

      data.roles.forEach((role) => posthog?.group(role, role));

      setUserLoginId(data.loginId);
      dispatch(
        setUserConnect({
          ...data,
          userId: data.id,
        })
      );

      if (location.pathname.includes("login")) {
        const { defaultModelOrView, defaultModule } = data;
        let navigationRoute = "";

        if (defaultModelOrView) {
          navigationRoute = `/${kebabCase(defaultModule)}/${kebabCase(
            defaultModelOrView
          )}`;
        } else if (defaultModule) {
          navigationRoute = `/${defaultModule}/tasks`;
        }

        if (navigationRoute) {
          navigate(navigationRoute);
        }
      }
    },
    onError: () => {
      onLoggedOut();
    },
  });

  useEffect(() => {
    const token = getToken();

    if (token && !isLoggedIn) {
      verifyToken.mutate();
    }

    if (!token) onLoggedOut();
  }, []);

  const dispatchActiveApp = useCallback(
    (appId) => {
      const app = apps.find(
        (a) => a.value.toLowerCase() === appId.toLowerCase()
      );

      if (app && app.value !== activeApp?.value) {
        dispatch(setActiveApp(app));
      }
    },
    [activeApp, apps]
  );

  useEffect(() => {
    if (!apps.length && !isLoggedIn) return;

    posthog?.capture("$pageview");

    if (location.pathname.includes("/admin/")) {
      dispatchActiveApp("admin");
    }

    if (location.pathname.includes("/leasing/")) {
      dispatchActiveApp("leasing");
    }

    if (location.pathname.includes("/facility-management/")) {
      dispatchActiveApp("facilitymanagement");
    }

    if (location.pathname.includes("/finance/")) {
      dispatchActiveApp("finance");
    }

    if (location.pathname.includes("/sales/")) {
      dispatchActiveApp("sales");
    }

    if (location.pathname.includes("/warehousing/")) {
      dispatchActiveApp("warehousing");
    }
  }, [location, dispatchActiveApp, isLoggedIn]);

  const isLoading =
    verifyToken.isLoading ||
    isLoadingUser ||
    isLoadingPermissions ||
    isLoadingNavbarItems;

  return (
    <>
      <IconSprite />
      <Routes>
        <Route path="/" element={<AuthPageLayout />}>
          <Route
            index
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/admin/tasks" />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:code" element={<ResetPassword />} />
        </Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
        {/* Admin Routes */}
        <Route
          element={(
            <RequirePermission
              parent="Module"
              scope="titles"
              action="Admin"
              isFromRoutes
            />
          )}
        >
          <Route
            path="/admin"
            element={<DashboardPageLayout isLoading={isLoading} />}
          >
            <Route path="dynamic-object">
              <Route index element={<DynamicObjectManager />} />
              <Route path=":objectKey">
                <Route path="fields">
                  <Route index element={<DynamicObjectFieldsManager />} />
                </Route>
              </Route>
            </Route>
            <Route path="dynamic-dashboard">
              <Route index element={<DynamicDashboardManager />} />
              <Route path=":schemaId" element={<DynamicDashboard />} />
            </Route>
            <Route path="tasks" element={<TasksDashboard />} />
            <Route
              path="user-management"
              element={(
                <DynamicObjectOverview
                  objectName={dynamicObjectMap.get("EmployeeObjectName")}
                  showFilters
                  showActionCell={false}
                  navigationLink="/admin/user-management"
                  showActionButton={false}
                  showExportButton={false}
                  title="User Management"
                />
              )}
            />
            <Route path="user-management/:id" element={<UserManagement />} />
            <Route path="tasks/:taskId" element={<ViewTask />} />
            <Route path="company-settings">
              <Route index element={<CompanySettingsListView />} />
              <Route path="new" element={<CompanySettings />} />
              <Route path=":id" element={<CompanySettings />} />
            </Route>
            {/* Note: Keep these route at the end */}
            <Route
              path=":objectName"
              element={<DynamicObjectOverview showFilters />}
            />
            <Route path=":objectName/new" element={<DynamicObjectForm />} />
            <Route
              path=":objectName/details/:id"
              element={<DynamicObjectDetails />}
            />
            <Route path=":objectName/:id" element={<DynamicObjectForm />} />
          </Route>
        </Route>
        {/* Facility Management Routes */}
        <Route
          element={(
            <RequirePermission
              parent="Module"
              scope="titles"
              action="FacilityManagement"
              isFromRoutes
            />
          )}
        >
          <Route
            path="/facility-management"
            element={<DashboardPageLayout isLoading={isLoading} />}
          >
            <Route path="submit-quotation">
              <Route
                path=":requestForQuotation"
                element={<SubmitQuotationForm />}
              />
            </Route>
            <Route
              path="service-request/new"
              element={<ServiceRequestForm />}
            />
            <Route path="plan-board" element={<PlanBoard />} />
            <Route path="move-in-request/new" element={<MoveInRequestForm />} />
            <Route path="technician" element={<TechnicianOverview />} />
            <Route
              path="technician/details/:technicianId"
              element={<TechnicianDetails />}
            />
            <Route path="employee/new" element={<EmployeeForm />} />
            <Route path="work-order/details/:workOrderId">
              <Route index element={<WorkOrderDetails />} />
            </Route>
            <Route path="petty-cash-transactions">
              <Route index element={<PettyCashTransactionOverview />} />
            </Route>
            <Route path="request-for-quotation">
              <Route index element={<RequestForQuotationOverview />} />
            </Route>
            <Route path="petty-cash-dashboard">
              <Route index element={<PettyCashDashboard />} />
            </Route>
            <Route path="request-for-quotation/new">
              <Route index element={<RequestForQuotationForm />} />
            </Route>
            <Route path="request-for-quotation/:id">
              <Route index element={<RequestForQuotationForm />} />
            </Route>
            <Route
              path="work-order-jobs/:id/propose-time"
              element={<ProposeJobTimeForm />}
            />
            <Route path="work-orders" element={<WorkOrderOverview />} />
            <Route path="work-order" element={<WorkOrderOverview />} />
            <Route path="work-order/new" element={<WorkOrderForm />} />
            <Route path="work-order/:id" element={<WorkOrderForm />} />
            <Route
              path="proforma-invoice/new"
              element={<ProformaInvoiceForm />}
            />
            <Route
              path="proforma-invoice/:id"
              element={<ProformaInvoiceForm />}
            />
            <Route
              path="petty-cash-claim/new"
              element={<PettyCashClaimForm />}
            />
            <Route
              path="petty-cash-claim/:id"
              element={<PettyCashClaimForm />}
            />
            <Route
              path="supervisor-petty-cash-request/new"
              element={<SupervisorPettyCashRequestForm />}
            />
            <Route
              path="supervisor-petty-cash-request/:id"
              element={<SupervisorPettyCashRequestForm />}
            />
            <Route
              path="visual-inspection/new"
              element={<VisualInspectionForm />}
            />
            <Route
              path="visual-inspection/:id"
              element={<VisualInspectionForm />}
            />
            <Route
              path="incident-report/new"
              element={<IncidentReportForm />}
            />
            <Route
              path="incident-report/:id"
              element={<IncidentReportForm />}
            />
            <Route
              path="blanket-agreement/new"
              element={<BlanketAgreementForm />}
            />
            <Route
              path="blanket-agreement/:id"
              element={<BlanketAgreementForm />}
            />
            <Route path="supplier/new" element={<SupplierForm />} />
            <Route
              path="blanket-agreement"
              element={<BlanketAgreementOverview />}
            />
            <Route
              path="blanket-agreements"
              element={<BlanketAgreementOverview />}
            />
            <Route path="dashboard" element={<FacilityDashboard />} />
            <Route path="tasks" element={<TasksDashboard />} />
            <Route path="tasks/:taskId" element={<ViewTask />} />
            {/* Note: Keep these route at the end */}
            <Route
              path=":objectName"
              element={<DynamicObjectOverview showFilters />}
            />
            <Route path=":objectName/new" element={<DynamicObjectForm />} />
            <Route
              path=":objectName/details/:id"
              element={<DynamicObjectDetails />}
            />
            <Route path=":objectName/:id" element={<DynamicObjectForm />} />
          </Route>
        </Route>
        {/* Finance Routes */}
        <Route
          element={(
            <RequirePermission
              parent="Module"
              scope="titles"
              action="Finance"
              isFromRoutes
            />
          )}
        >
          <Route
            path="/finance"
            element={<DashboardPageLayout isLoading={isLoading} />}
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="revenue-recognition/new"
              element={<RevenueRecognitionForm />}
            />
            <Route
              path="expense-recognition/new"
              element={<ExpenseRecognitionForm />}
            />

            <Route path="item/new" element={<ItemForm />} />
            <Route path="item/:id" element={<ItemForm />} />
            <Route path="chart-of-accounts">
              <Route index element={<ChartOfAccounts />} />
            </Route>
            <Route path="ledger-account">
              <Route index element={<ChartOfAccounts />} />
              <Route path="new" element={<AddGLAccount />} />
              <Route path="details/:id" element={<StaticObjectDetails />} />
              <Route path=":id" element={<AddGLAccount />} />
            </Route>
            <Route path="tax-rules">
              <Route index element={<TaxRule />} />
            </Route>
            <Route path="tax-rule">
              <Route index element={<TaxRule />} />
              <Route path="new" element={<AddTaxRule />} />
              <Route path="details/:id" element={<StaticObjectDetails />} />
              <Route path=":id" element={<AddTaxRule />} />
            </Route>
            <Route path="currencies">
              <Route index element={<Currency />} />
            </Route>
            <Route path="currency">
              <Route index element={<Currency />} />
              <Route path="new" element={<AddCurrency />} />
              <Route path="details/:id" element={<StaticObjectDetails />} />
              <Route path=":id" element={<AddCurrency />} />
            </Route>
            <Route path="accounting-periods">
              <Route index element={<AccountingPeriod />} />
            </Route>
            <Route path="accounting-period">
              <Route index element={<AccountingPeriod />} />
              <Route path="new" element={<AddAccountingPeriod />} />
              <Route path="details/:id" element={<StaticObjectDetails />} />
              <Route path=":id" element={<AddAccountingPeriod />} />
              <Route path=":id/:interimPeriodId/close" element={<CloseAccountingPeriod />} />
            </Route>
            <Route path="budgets">
              <Route index element={<Budget />} />
            </Route>
            <Route path="budget">
              <Route index element={<Budget />} />
              <Route path="new" element={<BudgetForm />} />
              <Route path=":id" element={<BudgetForm />} />
            </Route>
            <Route path="budget-categories">
              <Route index element={<BudgetCategory />} />
            </Route>
            <Route path="budget-category">
              <Route index element={<BudgetCategory />} />
              <Route path="new" element={<BudgetCategoryForm />} />
              <Route path="details/:id" element={<StaticObjectDetails />} />
              <Route path=":id" element={<BudgetCategoryForm />} />
            </Route>
            <Route path="journal-entry">
              <Route index element={<JournalEntryList />} />
              <Route path="new" element={<JournalEntry />} />
              <Route path=":id" element={<JournalEntry />} />
            </Route>
            <Route
              path="blanket-agreement/new"
              element={<BlanketAgreementForm />}
            />
            <Route
              path="blanket-agreement/:id"
              element={<BlanketAgreementForm />}
            />
            <Route path="reports" element={<ReportList />} />
            <Route
              path="reports/tenant-statement/:id"
              element={<ReportTenantStatement />}
            />
            <Route
              path="reports/petty-cash-by-supervisor-report-by-id"
              element={<ReportPettyCashBySupervisorStatementWrapper />}
            />
            <Route
              path="reports/petty-cash-claim-report-by-id/:id"
              element={<ReportPettyCashClaimStatementWrapper />}
            />
            <Route path="reports/:reportType" element={<Report />} />
            <Route
              path="reports/supplier-statement-by-id/:id"
              element={<ReportSupplierStatementWrapper />}
            />
            <Route
              path="reports/supplier-paid-invoices-by-id/:id"
              element={<ReportSupplierPaidInvoicesWrapper />}
            />
            <Route
              path="reports/bank-account-statement-by-id/:id"
              element={<ReportBankAccountStatementWrapper />}
            />
            <Route path="purchase-order/new" element={<PurchaseOrderForm />} />
            <Route path="purchase-order/:id" element={<PurchaseOrderForm />} />
            <Route path="goods-receipt-note/new" element={<GRNForm />} />
            <Route path="payment/new" element={<PaymentForm />} />
            <Route
              path="supplier-credit-note/new"
              element={<SupplierCreditNoteForm />}
            />
            <Route path="amc-invoice/new" element={<AMCInvoiceForm />} />
            <Route path="amc-invoice/:id" element={<AMCInvoiceForm />} />
            <Route path="depreciation/new" element={<DepreciationForm />} />
            <Route path="revaluation/new" element={<RevaluationForm />} />
            <Route
              path="purchase-invoice/new"
              element={<PurchaseInvoiceForm />}
            />
            <Route
              path="purchase-invoice/:id"
              element={<PurchaseInvoiceForm />}
            />
            <Route path="reservation/new" element={<ReservationForm />} />
            <Route
              path="general-invoice/new"
              element={<GeneralInvoiceForm />}
            />
            <Route
              path="general-receipt/new"
              element={<GeneralReceiptForm />}
            />
            <Route path="expense/new" element={<ExpenseForm />} />
            <Route path="expense/:id" element={<ExpenseForm />} />
            <Route path="receipt/new" element={<PrintReceiptForm />} />
            <Route path="bulk-receipt/new" element={<BulkReceiptForm />} />
            <Route path="bulk-invoice/new" element={<BulkInvoiceForm />} />
            <Route path="clear-cheque/new" element={<ClearChequeForm />} />
            <Route path="warehouse-cheque/new" element={<WarehouseChequeForm />} />
            <Route path="bounce-cheque/new" element={<BounceChequeForm />} />
            <Route path="recall-cheque/new" element={<RecallChequeForm />} />
            <Route
              path="customer-quotation/new"
              element={<CustomerQuotationForm />}
            />
            <Route
              path="payment-receipt/new"
              element={<PaymentReceiptForm />}
            />
            <Route
              path="payment-receipt/:id"
              element={<PaymentReceiptForm />}
            />
            <Route path="rental-invoice/new" element={<RentalInvoiceForm />} />
            <Route path="credit-note/new" element={<CreditNoteForm />} />
            <Route path="request-for-quotation/new">
              <Route index element={<RequestForQuotationForm />} />
            </Route>
            <Route path="request-for-quotation/:id">
              <Route index element={<RequestForQuotationForm />} />
            </Route>
            <Route
              path="bank-reconciliation/new"
              element={<BankReconciliation />}
            />
            <Route path="asset-register" element={<AssetRegisterOverview />} />
            <Route path="tasks" element={<TasksDashboard />} />
            <Route path="tasks/:taskId" element={<ViewTask />} />
            {/* Note: Keep these route at the end */}
            <Route
              path=":objectName"
              element={<DynamicObjectOverview showFilters />}
            />
            <Route path=":objectName/new" element={<DynamicObjectForm />} />
            <Route
              path=":objectName/details/:id"
              element={<DynamicObjectDetails />}
            />
            <Route path=":objectName/:id" element={<DynamicObjectForm />} />
          </Route>
        </Route>
        {/* Leasing Routes */}
        <Route
          element={(
            <RequirePermission
              parent="Module"
              scope="titles"
              action="Leasing"
              isFromRoutes
            />
          )}
        >
          <Route
            path="/leasing"
            element={<DashboardPageLayout isLoading={isLoading} />}
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="move-in-request/new" element={<MoveInRequestForm />} />
            <Route
              path="move-in-check-list/new"
              element={<MoveInCheckListForm />}
            />
            <Route
              path="building/details/:buildingId"
              element={<BuildingDetails />}
            />
            <Route path="unit/details/:unitId" element={<UnitDetails />} />
            <Route
              path="tenant/details/:tenantId"
              element={<TenantDetails />}
            />
            <Route path="building/new" element={<BuildingForm />} />
            <Route
              path="blanket-agreement/new"
              element={<BlanketAgreementForm />}
            />
            <Route path="reservation/new" element={<ReservationForm />} />
            <Route path="contract/new" element={<ContractForm />} />
            <Route path="contract/:id" element={<ContractForm />} />
            <Route
              path="contract-termination/new"
              element={<ContractTerminationForm />}
            />
            <Route
              path="contract-termination/:id"
              element={<ContractTerminationForm />}
            />
            <Route path="tenant/new" element={<TenantForm />} />
            <Route path="unit/new" element={<UnitForm />} />
            <Route path="unit/:id" element={<UnitForm />} />
            <Route
              path="facility-contract/new"
              element={<FacilityContractForm />}
            />
            <Route
              path="customer-quotation/new"
              element={<CustomerQuotationForm />}
            />
            <Route
              path="payment-receipt/new"
              element={<PaymentReceiptForm />}
            />
            <Route
              path="payment-receipt/:id"
              element={<PaymentReceiptForm />}
            />
            <Route path="rental-invoice/new" element={<RentalInvoiceForm />} />
            <Route path="credit-note/new" element={<CreditNoteForm />} />
            <Route path="tasks" element={<TasksDashboard />} />
            <Route path="tasks/:taskId" element={<ViewTask />} />
            <Route path="contract" element={<ContractOverview />} />
            <Route path="contracts" element={<ContractOverview />} />
            <Route
              path="blanket-agreement"
              element={<BlanketAgreementOverview />}
            />
            <Route
              path="blanket-agreements"
              element={<BlanketAgreementOverview />}
            />
            {/* Note: Keep these route at the end */}
            <Route
              path=":objectName"
              element={<DynamicObjectOverview showFilters />}
            />
            <Route path=":objectName/new" element={<DynamicObjectForm />} />
            <Route
              path=":objectName/details/:id"
              element={<DynamicObjectDetails />}
            />
            <Route path=":objectName/:id" element={<DynamicObjectForm />} />
          </Route>
        </Route>
        {/* Sales Routes */}
        <Route
          element={(
            <RequirePermission
              parent="Module"
              scope="titles"
              action="Sales"
              isFromRoutes
            />
          )}
        >
          <Route
            path="/sales"
            element={<DashboardPageLayout isLoading={isLoading} />}
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="tasks" element={<TasksDashboard />} />
            <Route path="unit-matching" element={<UnitMatching />} />
            <Route path="lead/new" element={<LeadForm />} />
            <Route path="customer/new" element={<CustomerForm />} />
            <Route path="leads" element={<LeadOverview />} />
            <Route path="lead" element={<LeadOverview />} />
            <Route path="tasks/:taskId" element={<ViewTask />} />
            {/* Note: Keep these route at the end */}
            <Route
              path=":objectName"
              element={<DynamicObjectOverview showFilters />}
            />
            <Route path=":objectName/new" element={<DynamicObjectForm />} />
            <Route
              path=":objectName/details/:id"
              element={<DynamicObjectDetails />}
            />
            <Route path=":objectName/:id" element={<DynamicObjectForm />} />
          </Route>
        </Route>
        {/* Warehouse Routes */}
        <Route
          element={(
            <RequirePermission
              parent="Module"
              scope="titles"
              action="Warehousing"
              isFromRoutes
            />
          )}
        >
          <Route
            path="/warehousing"
            element={<DashboardPageLayout isLoading={isLoading} />}
          >
            <Route path="item/new" element={<ItemForm />} />
            <Route path="tasks" element={<TasksDashboard />} />
            <Route path="tasks/:taskId" element={<ViewTask />} />
            {/* Note: Keep these route at the end */}
            <Route
              path=":objectName"
              element={<DynamicObjectOverview showFilters />}
            />
            <Route path=":objectName/new" element={<DynamicObjectForm />} />
            <Route
              path=":objectName/details/:id"
              element={<DynamicObjectDetails />}
            />
            <Route path=":objectName/:id" element={<DynamicObjectForm />} />
          </Route>
        </Route>
        <Route
          path="help"
          element={<DashboardPageLayout isLoading={isLoading} />}
        />
        <Route
          path="configuration"
          element={<DashboardPageLayout isLoading={isLoading} />}
        />
        <Route
          path="inbox"
          element={<DashboardPageLayout isLoading={isLoading} />}
        />
        <Route
          path="/notifications"
          element={<DashboardPageLayout isLoading={isLoading} />}
        >
          <Route index element={<Notifications />} />
        </Route>
        <Route
          path=":applicationName"
          element={<DashboardPageLayout isLoading={isLoading} />}
        >
          <Route
            path="tasks/:taskId/task-workflow"
            element={<TaskFlowChart />}
          />
          <Route path="user/profile" element={<ProfileSettings />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
